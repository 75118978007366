import aboutMe1 from '../../images/AboutMe1.png';
import aboutMe2 from '../../images/AboutMe2.jpeg';

export const AboutMeSliderData = [
    {
        aboutMe: aboutMe1
    },
    {
        aboutMe: aboutMe2
    }
]