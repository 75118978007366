import jazz1 from '../../images/Jazz1.jpeg';
import jazz2 from '../../images/Jazz2.jpeg';
import jazz3 from '../../images/Jazz3.jpeg';

export const JazzSliderData = [
    {
        jazz: jazz1
    },
    {
        jazz: jazz2

    },
    {
        jazz: jazz3

    }
]