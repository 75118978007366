import React from 'react'
import MainHeroSection from '../../MainHeroSection'

function Home() {
    return (
        <div>
            <MainHeroSection/>
        </div>
    )
}

export default Home;
